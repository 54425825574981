@import "components/button.css";
@import "components/form_fields.css";
@import "components/rating.css";
@import "components/range.css";
@import "components/datepicker.css";
@import "components/slider.css";
@import "components/modal.css";
@import "components/menu.css";
@import "components/other.css";
@import "components/elfsight_api.css";


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  * {
    outline-color: theme('colors.color-orange');
  }

  ::selection {
    @apply bg-orange text-white;
  }

  body {
    @apply relative min-w-[375px] text-dark bg-light;
  }

  body.open-filter {
    @apply max-lg:overflow-hidden;
  }

  body.open-menu .overlay {
    @apply lg-xl:block;
  }

  body.open-search .overlay {
    @apply block;
  }

  header {
    @apply relative z-10;
  }
  hr {
    @apply border-none h-px bg-color-border;
  }
}

.fancybox__toolbar {
  --f-button-bg: #FF5C00;
  --f-button-hover-bg: #FF8D4C;
}

.iti {
  display: block;
  --iti-spacer-horizontal: 16px;
  --iti-arrow-padding: 8px;
}

.tp-widget-wrapper {
  max-width: 100%!important;
  margin-left: 50px;
  margin-right: 50px;
}

[x-cloak] {
  display: none !important;
}

input[type="number"].appearance-none::-webkit-inner-spin-button,
input[type="number"].appearance-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"].appearance-none {
  -moz-appearance: textfield;
}
